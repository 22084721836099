import { TSubscriptionCurrencyCode } from "src/data/billing/types/billingTypes"

export function formatPrice(
  price: number,
  currencyCode: TSubscriptionCurrencyCode,
  minimumFractionDigits = 2,
  maximumFractionDigits = 2,
  removeDecimalsIfWhole = true
) {
  const decimals =
    removeDecimalsIfWhole && isWholeNumber(price) ? 0 : minimumFractionDigits

  try {
    const formatter = new Intl.NumberFormat(getLocale(currencyCode), {
      style: "currency",
      currency: currencyCode,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
    return formatter.format(price)
  } catch (err) {
    // eslint-disable-next-line no-console
    console.warn(err)
    return `${currencyCode.toUpperCase()} ${price}`
  }
}

function isWholeNumber(number: number) {
  return number % 1 === 0
}

function getLocale(currencyCode: string) {
  switch (currencyCode.toLowerCase()) {
    case "usd":
      return "en-US"
    case "eur":
      return "en-GB"
    case "gbp":
      return "en-GB"
    case "sek":
      return "sv-SE"
    default:
      return "en-US"
  }
}
